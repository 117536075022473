<template>
  <div id="app">
    <v-app id="inspire">
      <!-- absolute temporary -->
      <v-navigation-drawer v-model="drawer" app width="350px">
        <v-list nav height="80" class="mt-0 mb-2 pa-5">
          <a href="/">
            <img
              src="@/assets/logo/ทางเลือก-สองสี-หลังใส.png"
              width="auto"
              height="50"
              class=""
              style="float: left"
            />
          </a>
          <img
            src="@/assets/icon/menu.png"
            width="auto"
            height="25"
            style="float: right"
            @click.stop="drawer = !drawer"
          />
        </v-list>
        <v-divider></v-divider>

        <!-- <v-list
          v-for="(i, index) in navbar"
          :key="index"
          class="fn22"
          style="color: #4f4f4f"
        >
          <v-list-item @click="goTo(i)" class="py-6">
            <v-divider class="mb-4"></v-divider>
            <v-list-item-title class="mx-4">{{
              i.category_name
            }}</v-list-item-title>
          </v-list-item>
        </v-list> -->
        <v-list
          nav
          v-for="item in Navigator"
          :key="item.key"
          class="fn22"
          style="color: #4f4f4f"
        >
          <v-list-group v-if="item.menuItem">
            <v-list-item slot="activator">
              <!-- <v-list-item-title
                class="mt-1"
                style="font-size: 18px; color: #de5c8e; font-weight: 900"
              >
              </v-list-item-title> -->
              <!-- <v-icon>mdi-star</v-icon> -->
              <span
                class="mt-1"
                style="font-size: 18px; color: #de5c8e; font-weight: 900"
                v-if="EngLang"
                >{{ item.headerName_en }}</span
              >
              <span
                class="mt-1"
                style="font-size: 18px; color: #de5c8e; font-weight: 900"
                v-else
                >{{ item.headerName_th }}</span
              >
            </v-list-item>

            <v-list-item
              v-for="menuList in item.menuItem"
              :key="menuList.key"
              @click="ChangePage(menuList)"
              :class="{ highlighted: menuList.key === selectedItem }"
            >
              <v-list-item>
                <v-icon class="mr-2">mdi-file-document-outline</v-icon>
                <span style="font-size: 14px" v-if="EngLang">
                  {{ menuList.text_en }}</span
                >
                <span style="font-size: 14px" v-else>
                  {{ menuList.text_th }}</span
                >
              </v-list-item>
            </v-list-item>
          </v-list-group>
          <!-- <v-list-item v-else @click="ChangePage(item)" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" style="font-size: 16px">{{
                  item.text
                }}</span>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
          </v-list-item> -->
          <v-divider class="mt-4"></v-divider>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app elevation="4" color="white">
        <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
        <!-- <img
          src="@/assets/logo/ทางเลือก-สองสี-หลังใส.png"
          width="150"
          height="auto"
          class="pa-2 ma-4"
          style="float: left"
        /> -->
        <!-- <span style="font-size: 30px" class="mr-2">VISA System</span> -->
        <img
          v-if="!drawer"
          class="mr-2"
          src="@/assets/icon/menu.png"
          width="auto"
          height="25"
          style="float: right"
          @click.stop="drawer = !drawer"
        />
        <!-- <v-divider vertical inset v-if="!drawer"></v-divider> -->
        <v-spacer></v-spacer>

        <toggle-button
          v-model="EngLang"
          :labels="{ checked: 'ENG', unchecked: 'TH' }"
          :color="{ checked: '#79aeff', unchecked: '#ff7474' }"
          :switch-color="{ checked: '#1976d2', unchecked: '#ff3a3a' }"
          class="mr-2"
          @change="handlechangelanguage(EngLang)"
        />
        <div class="text-right mt-7 ml-4">
          <v-speed-dial
            v-model="fab"
            bottom
            right
            direction="bottom"
            :open-on-hover="false"
            :transition="transition"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" text center small>
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-icon v-else> mdi-account </v-icon>
              </v-btn>
            </template>

            <v-list min-width="110px" outlined>
              <v-list-item @click="goToProfile()">Profile</v-list-item>
              <v-list-item @click="Logout()">Logout</v-list-item>
              <!-- <v-divider>Language</v-divider>

              <v-list-item value="en" @click="handlechangelanguage('en')"
                >English</v-list-item
              >
              <v-list-item value="th" @click="handlechangelanguage('th')"
                >Thai</v-list-item
              > -->
            </v-list>
          </v-speed-dial>
          <!-- <select v-model="EngLang" @change="handlechangelanguage($event)"></select> -->
        </div>
      </v-app-bar>
      <v-main class="div_overflow" style="background-color: #e2e2e2">
        <div class="pa-4">
          <router-view></router-view>
        </div>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      userData: [],
      rewardSelected: 1,
      messages: 1,
      drawer: true,
      Header: "",
      // picture: "",
      username: "",
      selectedItem: "",

      navbar: [],
      NavigatorDefault: [
        {
          headerName_th: "ยื่นคำร้องขอเอกสาร / นิสิต",
          headerName_en: "Request for a Visa Document / Student",
          menuItem: [
            {
              key: "Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/Doc1",
              name: "Doc1",
            },
            {
              key: "Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/Doc2",
              name: "Doc2",
            },
            {
              key: "Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/Doc3",
              name: "Doc3",
            },
            {
              key: "Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/Doc4",
              name: "Doc4",
            },
            {
              key: "Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/Doc5",
              name: "Doc5",
            },
            {
              key: "Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/Doc6",
              name: "Doc6",
            },
          ],
        },

        {
          headerName_th: "ยื่นคำร้องขอเอกสาร / บุคลากร",
          headerName_en: "Request for a Visa Document / Personnel",
          menuItem: [
            {
              key: "Personnel-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/Personnel-Doc1",
              name: "Personnel-Doc1",
            },
            {
              key: "Personnel-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/Personnel-Doc2",
              name: "Personnel-Doc2",
            },
            {
              key: "Personnel-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/Personnel-Doc3",
              name: "Personnel-Doc3",
            },
            {
              key: "Personnel-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/Personnel-Doc4",
              name: "Personnel-Doc4",
            },
            {
              key: "Personnel-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/Personnel-Doc5",
              name: "Personnel-Doc5",
            },
            {
              key: "Personnel-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/Personnel-Doc6",
              name: "Personnel-Doc6",
            },
          ],
        },
        {
          headerName_th: "ยื่นคำร้องขอเอกสาร / ผู้ติดตามนิสิต",
          headerName_en: "Request for a Visa Document / Dependent (of Student)",
          menuItem: [
            {
              key: "FollowerStudent-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/FollowerStudent-Doc1",
              name: "FollowerStudent-Doc1",
            },
            {
              key: "FollowerStudent-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/FollowerStudent-Doc2",
              name: "FollowerStudent-Doc2",
            },
            {
              key: "FollowerStudent-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/FollowerStudent-Doc3",
              name: "FollowerStudent-Doc3",
            },
            {
              key: "FollowerStudent-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/FollowerStudent-Doc4",
              name: "FollowerStudent-Doc4",
            },
            {
              key: "FollowerStudent-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/FollowerStudent-Doc5",
              name: "FollowerStudent-Doc5",
            },
            {
              key: "FollowerStudent-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/FollowerStudent-Doc6",
              name: "FollowerStudent-Doc6",
            },
          ],
        },
        {
          headerName_th: "ยื่นคำร้องขอเอกสาร / ผู้ติดตามบุคลากร",
          headerName_en:
            "Request for a Visa Document / Dependent (of Personnel)",
          menuItem: [
            {
              key: "Follower-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/Follower-Doc1",
              name: "Follower-Doc1",
            },
            {
              key: "Follower-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/Follower-Doc2",
              name: "Follower-Doc2",
            },
            {
              key: "Follower-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/Follower-Doc3",
              name: "Follower-Doc3",
            },
            {
              key: "Follower-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/Follower-Doc4",
              name: "Follower-Doc4",
            },
            {
              key: "Follower-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/Follower-Doc5",
              name: "Follower-Doc5",
            },
            {
              key: "Follower-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/Follower-Doc6",
              name: "Follower-Doc6",
            },
          ],
        },
        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /นิสิต",
          headerName_en: "List of Request for a Visa Document / Student",
          menuItem: [
            {
              key: "List-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-Doc1",
              name: "List-Doc1",
            },
            {
              key: "List-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-Doc2",
              name: "List-Doc2",
            },
            {
              key: "List-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-Doc3",
              name: "List-Doc3",
            },
            {
              key: "List-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-Doc4",
              name: "List-Doc4",
            },
            {
              key: "List-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-Doc5",
              name: "List-Doc5",
            },
            {
              key: "List-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-Doc6",
              name: "List-Doc6",
            },
          ],
        },

        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /บุคลากร",
          headerName_en: "List of Request for a Visa Document / Personnel",
          menuItem: [
            {
              key: "List-Personnel-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-Personnel-Doc1",
              name: "List-Personnel-Doc1",
            },
            {
              key: "List-Personnel-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-Personnel-Doc2",
              name: "List-Personnel-Doc2",
            },
            {
              key: "List-Personnel-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-Personnel-Doc3",
              name: "List-Personnel-Doc3",
            },
            {
              key: "List-Personnel-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-Personnel-Doc4",
              name: "List-Personnel-Doc4",
            },
            {
              key: "List-Personnel-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-Personnel-Doc5",
              name: "List-Personnel-Doc5",
            },
            {
              key: "List-Personnel-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-Personnel-Doc6",
              name: "List-Personnel-Doc6",
            },
          ],
        },
        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /ผู้ติดตามนิสิต",
          headerName_en:
            "List of Request for a Visa Document / Dependent (of Student)",
          menuItem: [
            {
              key: "List-FollowerStudent-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-FollowerStudent-Doc1",
              name: "List-FollowerStudent-Doc1",
            },
            {
              key: "List-FollowerStudent-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-FollowerStudent-Doc2",
              name: "List-FollowerStudent-Doc2",
            },
            {
              key: "List-FollowerStudent-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-FollowerStudent-Doc3",
              name: "List-FollowerStudent-Doc3",
            },
            {
              key: "List-FollowerStudent-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-FollowerStudent-Doc4",
              name: "List-FollowerStudent-Doc4",
            },
            {
              key: "List-FollowerStudent-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-FollowerStudent-Doc5",
              name: "List-FollowerStudent-Doc5",
            },
            {
              key: "List-FollowerStudent-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-FollowerStudent-Doc6",
              name: "List-FollowerStudent-Doc6",
            },
          ],
        },
        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /ผู้ติดตามบุคลากร",
          headerName_en:
            "List of Request for a Visa Document / Dependent (of Personnel)",
          menuItem: [
            {
              key: "List-Follower-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-Follower-Doc1",
              name: "List-Follower-Doc1",
            },
            {
              key: "List-Follower-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-Follower-Doc2",
              name: "List-Follower-Doc2",
            },
            {
              key: "List-Follower-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-Follower-Doc3",
              name: "List-Follower-Doc3",
            },
            {
              key: "List-Follower-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-Follower-Doc4",
              name: "List-Follower-Doc4",
            },
            {
              key: "List-Follower-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-Follower-Doc5",
              name: "List-Follower-Doc5",
            },
            {
              key: "List-Follower-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-Follower-Doc6",
              name: "List-Follower-Doc6",
            },
          ],
        },

        {
          headerName_th: "QR Code",
          headerName_en: "QR Code",
          menuItem: [
            // {
            //   key: "Doc7",
            //   text_th: "กรอกข้อมูล document",
            //   text_en:
            //     "กรอกข้อมูล document",
            //   path: "/Doc7",
            //   name: "Doc7",
            // },
            // {
            //   key: "Doc8",
            //   text_th: "กรอกข้อมูล QR Code",
            //   text_en: "กรอกข้อมูล QR Code",
            //   path: "/Doc8",
            //   name: "Doc8",
            // },

            {
              key: "ManageDocument",
              text_th: "ลิสต์ประเภทเอกสาร",
              text_en: " Document",
              path: "/ManageDocument",
              name: "ManageDocument",
            },
            {
              key: "ManageQR",
              text_th: "ลิสต์ QR Code",
              text_en: "ลิสต์ QR Code",
              path: "/ManageQR",
              name: "ManageQR",
            },
            // {
            //   key: "Doc9",
            //   text_th: "display QR Code",
            //   text_en: "display QR Code",
            //   path: "/View-Doc9",
            //   name: "View-qrcode",
            // },
            // {
            //   key: "view qrcode",
            //   text_th: "display QR Code",
            //   text_en: "dosplay QR Code",
            //   path: "/View-doc10",
            //   name: "View-doc10",
            // },
          ],
        },

        {
          headerName_th: "จัดการระบบ",
          headerName_en: "Setting",
          menuItem: [
            {
              key: "ManageCountry",
              text_th: "ประเทศ",
              text_en: "Country",
              path: "/ManageCountry",
              name: "ManageCountry",
            },
            {
              key: "ManageCity",
              text_th: "เมือง",
              text_en: "City",
              path: "/ManageCity",
              name: "ManageCity",
            },
            {
              key: "ManageEmbassy",
              text_th: "สถานฑูต",
              text_en: "Embassy",
              path: "/ManageEmbassy",
              name: "ManageEmbassy",
            },
            // {
            //   key: "ManageNationality",
            //   text_th: "สัญชาติ",
            //   text_en: "Manage Nationality",
            //   path: "/ManageNationality",
            //   name: "ManageNationality",
            // },
            {
              key: "ManageAffilationFaculty",
              text_th: "ส่วนงาน/คณะ",
              text_en: "Affilation/Faculty",
              path: "/ManageAffilationFaculty",
              name: "ManageAffilationFaculty",
            },
            // {
            //   key: "ManageBoosterApplication",
            //   text_th: "ชื่อวัคซีน (เข็มบูท)",
            //   text_en: "Booster Vaccine",
            //   path: "/ManageBoosterApplication",
            //   name: "ManageBoosterApplication",
            // },
            {
              key: "ManageCovidVaccine",
              text_th: "ชื่อวัคซีน",
              text_en: "Covid19 Vaccine",
              path: "/ManageCovidVaccine",
              name: "ManageCovidVaccine",
            },
            {
              key: "ManageDepartment",
              text_th: "แผนก",
              text_en: "Department",
              path: "/ManageDepartment",
              name: "ManageDepartment",
            },

            {
              key: "ManageExchangeVisittingStudent",
              text_th: "ประเภทนักศึกษาแลกเปลี่ยน",
              text_en: "Exchange Visitting Student",
              path: "/ManageExchangeVisittingStudent",
              name: "ManageExchangeVisittingStudent",
            },
            {
              key: "ManageNationality",
              text_th: "สัญชาติ",
              text_en: "Nationality",
              path: "/ManageNationality",
              name: "ManageNationality",
            },
            {
              key: "ManageRelationWithHolder",
              text_th: "ความสัมพันธ์กับผู้ถือวีซ่าหลัก",
              text_en: "Relationship with visa holders",
              path: "/ManageRelationWithHolder",
              name: "ManageRelationWithHolder",
            },
            {
              key: "ManageReasonForVisaCancellation",
              text_th: "เหตุผลในการขอยกเลิกวีซ่า",
              text_en: "Visa Cancellation Reasons",
              path: "/ManageReasonForVisaCancellation",
              name: "ManageReasonForVisaCancellation",
            },
            {
              key: "ManageLevel",
              text_th: "ระดับการศึกษา",
              text_en: "Academic level",
              path: "/ManageLevel",
              name: "ManageLevel",
            },
            {
              key: "ManageStudentType",
              text_th: "ประเภทนักศึกษา",
              text_en: "Student Type",
              path: "/ManageStudentType",
              name: "ManageStudentType",
            },
            // {
            //   key: "ManageSub_StudentType",
            //   text_th: "ประเภทนักศึกษาส่วนย่อย",
            //   text_en: "Sub Student Type",
            //   path: "/ManageSub_StudentType",
            //   name: "ManageSub_StudentType",
            // },
            // {
            //   key: "ManageSupportingDocument",
            //   text_th: "หนังสือซัพพอร์ต",
            //   text_en: "Supporting Document",
            //   path: "/ManageSupportingDocument",
            //   name: "ManageSupportingDocument",
            // },
            {
              key: "ManageTypeOfWorks",
              text_th: "ประเภทงาน",
              text_en: "Type of Work",
              path: "/ManageTypeOfWorks",
              name: "ManageTypeOfWorks",
            },
            {
              key: "ManageVisaType",
              text_th: "ประเภทวีซ่า",
              text_en: "VISA Type",
              path: "/ManageVisaType",
              name: "ManageVisaType",
            },
            {
              key: "ManageUser",
              text_th: "จัดการผู้ใช้งาน",
              text_en: "User",
              path: "/ManageUser",
              name: "ManageUser",
            },
            {
              key: "ManageUserAdmin",
              text_th: "จัดการแอดมิน",
              text_en: "UserAdmin",
              path: "/ManageUserAdmin",
              name: "ManageUserAdmin",
            },
          ],
        },
      ],

      NavigatorAdmin: [
        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /นิสิต",
          headerName_en: "List of Request for a Visa Document / Student",
          menuItem: [
            {
              key: "List-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-Doc1",
              name: "List-Doc1",
            },
            {
              key: "List-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-Doc2",
              name: "List-Doc2",
            },
            {
              key: "List-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-Doc3",
              name: "List-Doc3",
            },
            {
              key: "List-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-Doc4",
              name: "List-Doc4",
            },
            {
              key: "List-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-Doc5",
              name: "List-Doc5",
            },
            {
              key: "List-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-Doc6",
              name: "List-Doc6",
            },
          ],
        },
        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /บุคลากร",
          headerName_en: "List of Request for a Visa Document / Personnel",
          menuItem: [
            {
              key: "List-Personnel-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-Personnel-Doc1",
              name: "List-Personnel-Doc1",
            },
            {
              key: "List-Personnel-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-Personnel-Doc2",
              name: "List-Personnel-Doc2",
            },
            {
              key: "List-Personnel-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-Personnel-Doc3",
              name: "List-Personnel-Doc3",
            },
            {
              key: "List-Personnel-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-Personnel-Doc4",
              name: "List-Personnel-Doc4",
            },
            {
              key: "List-Personnel-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-Personnel-Doc5",
              name: "List-Personnel-Doc5",
            },
            {
              key: "List-Personnel-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-Personnel-Doc6",
              name: "List-Personnel-Doc6",
            },
          ],
        },
        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /ผู้ติดตามนิสิต",
          headerName_en:
            "List of Request for a Visa Document / Dependent (of Student)",
          menuItem: [
            {
              key: "List-FollowerStudent-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-FollowerStudent-Doc1",
              name: "List-FollowerStudent-Doc1",
            },
            {
              key: "List-FollowerStudent-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-FollowerStudent-Doc2",
              name: "List-FollowerStudent-Doc2",
            },
            {
              key: "List-FollowerStudent-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-FollowerStudent-Doc3",
              name: "List-FollowerStudent-Doc3",
            },
            {
              key: "List-FollowerStudent-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-FollowerStudent-Doc4",
              name: "List-FollowerStudent-Doc4",
            },
            {
              key: "List-FollowerStudent-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-FollowerStudent-Doc5",
              name: "List-FollowerStudent-Doc5",
            },
            {
              key: "List-FollowerStudent-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-FollowerStudent-Doc6",
              name: "List-FollowerStudent-Doc6",
            },
          ],
        },
        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /ผู้ติดตามบุคลากร",
          headerName_en:
            "List of Request for a Visa Document / Dependent (of Personnel)",
          menuItem: [
            {
              key: "List-Follower-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-Follower-Doc1",
              name: "List-Follower-Doc1",
            },
            {
              key: "List-Follower-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-Follower-Doc2",
              name: "List-Follower-Doc2",
            },
            {
              key: "List-Follower-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-Follower-Doc3",
              name: "List-Follower-Doc3",
            },
            {
              key: "List-Follower-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-Follower-Doc4",
              name: "List-Follower-Doc4",
            },
            {
              key: "List-Follower-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-Follower-Doc5",
              name: "List-Follower-Doc5",
            },
            {
              key: "List-Follower-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-Follower-Doc6",
              name: "List-Follower-Doc6",
            },
          ],
        },
        {
          headerName_th: "จัดการบัญชี",
          headerName_en: "Profile Setting",
          menuItem: [
            {
              key: "ManageChangePassword",
              text_th: "เปลี่ยนรหัสผ่าน",
              text_en: "Change Password",
              path: "/ManageChangePassword",
              name: "ManageChangePassword",
            },
          ],
        },
      ],

      NavigatorStudent: [
        {
          headerName_th: "ยื่นคำร้องขอเอกสาร / นิสิต",
          headerName_en: "Request for a Visa Document / Student",
          menuItem: [
            {
              key: "Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/Doc1",
              name: "Doc1",
            },
            {
              key: "Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/Doc2",
              name: "Doc2",
            },
            {
              key: "Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/Doc3",
              name: "Doc3",
            },
            {
              key: "Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/Doc4",
              name: "Doc4",
            },
            {
              key: "Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/Doc5",
              name: "Doc5",
            },
            {
              key: "Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/Doc6",
              name: "Doc6",
            },
          ],
        },
        {
          headerName_th: "ยื่นคำร้องขอเอกสาร / ผู้ติดตาม",
          headerName_en: "Request for a Visa Document / Dependent",
          menuItem: [
            {
              key: "FollowerStudent-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/FollowerStudent-Doc1",
              name: "FollowerStudent-Doc1",
            },
            {
              key: "FollowerStudent-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/FollowerStudent-Doc2",
              name: "FollowerStudent-Doc2",
            },
            {
              key: "FollowerStudent-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/FollowerStudent-Doc3",
              name: "FollowerStudent-Doc3",
            },
            {
              key: "FollowerStudent-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/FollowerStudent-Doc4",
              name: "FollowerStudent-Doc4",
            },
            {
              key: "FollowerStudent-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/FollowerStudent-Doc5",
              name: "FollowerStudent-Doc5",
            },
            {
              key: "FollowerStudent-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/FollowerStudent-Doc6",
              name: "FollowerStudent-Doc6",
            },
          ],
        },
        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /นิสิต",
          headerName_en: "List of Request for a Visa Document / Student",
          menuItem: [
            {
              key: "List-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-Doc1",
              name: "List-Doc1",
            },
            {
              key: "List-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-Doc2",
              name: "List-Doc2",
            },
            {
              key: "List-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-Doc3",
              name: "List-Doc3",
            },
            {
              key: "List-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-Doc4",
              name: "List-Doc4",
            },
            {
              key: "List-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-Doc5",
              name: "List-Doc5",
            },
            {
              key: "List-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-Doc6",
              name: "List-Doc6",
            },
          ],
        },
        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /ผู้ติดตาม",
          headerName_en:
            "List of Request for a Visa Document / Dependent (of Student)",
          menuItem: [
            {
              key: "List-FollowerStudent-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-FollowerStudent-Doc1",
              name: "List-FollowerStudent-Doc1",
            },
            {
              key: "List-FollowerStudent-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-FollowerStudent-Doc2",
              name: "List-FollowerStudent-Doc2",
            },
            {
              key: "List-FollowerStudent-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-FollowerStudent-Doc3",
              name: "List-FollowerStudent-Doc3",
            },
            {
              key: "List-FollowerStudent-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-FollowerStudent-Doc4",
              name: "List-FollowerStudent-Doc4",
            },
            {
              key: "List-FollowerStudent-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-FollowerStudent-Doc5",
              name: "List-FollowerStudent-Doc5",
            },
            {
              key: "List-FollowerStudent-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-FollowerStudent-Doc6",
              name: "List-FollowerStudent-Doc6",
            },
          ],
        },
      ],

      NavigatorTeacher: [
        {
          headerName_th: "ยื่นคำร้องขอเอกสาร / บุคลากร",
          headerName_en: "Request for a Visa Document / Personnel",
          menuItem: [
            {
              key: "Personnel-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/Personnel-Doc1",
              name: "Personnel-Doc1",
            },
            {
              key: "Personnel-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/Personnel-Doc2",
              name: "Personnel-Doc2",
            },
            {
              key: "Personnel-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/Personnel-Doc3",
              name: "Personnel-Doc3",
            },
            {
              key: "Personnel-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/Personnel-Doc4",
              name: "Personnel-Doc4",
            },
            {
              key: "Personnel-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/Personnel-Doc5",
              name: "Personnel-Doc5",
            },
            {
              key: "Personnel-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/Personnel-Doc6",
              name: "Personnel-Doc6",
            },
          ],
        },
        {
          headerName_th: "ยื่นคำร้องขอเอกสาร / ผู้ติดตาม",
          headerName_en: "Request for a Visa Document / Dependent",
          menuItem: [
            {
              key: "Follower-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/Follower-Doc1",
              name: "Follower-Doc1",
            },
            {
              key: "Follower-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/Follower-Doc2",
              name: "Follower-Doc2",
            },
            {
              key: "Follower-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/Follower-Doc3",
              name: "Follower-Doc3",
            },
            {
              key: "Follower-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/Follower-Doc4",
              name: "Follower-Doc4",
            },
            {
              key: "Follower-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/Follower-Doc5",
              name: "Follower-Doc5",
            },
            {
              key: "Follower-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/Follower-Doc6",
              name: "Follower-Doc6",
            },
          ],
        },
        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /บุคลากร",
          headerName_en: "List of Request for a Visa Document / Personnel",
          menuItem: [
            {
              key: "List-Personnel-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-Personnel-Doc1",
              name: "List-Personnel-Doc1",
            },
            {
              key: "List-Personnel-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-Personnel-Doc2",
              name: "List-Personnel-Doc2",
            },
            {
              key: "List-Personnel-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-Personnel-Doc3",
              name: "List-Personnel-Doc3",
            },
            {
              key: "List-Personnel-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-Personnel-Doc4",
              name: "List-Personnel-Doc4",
            },
            {
              key: "List-Personnel-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-Personnel-Doc5",
              name: "List-Personnel-Doc5",
            },
            {
              key: "List-Personnel-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-Personnel-Doc6",
              name: "List-Personnel-Doc6",
            },
          ],
        },
        {
          headerName_th: "ลิสต์ยื่นคำร้องขอเอกสาร /ผู้ติดตาม",
          headerName_en:
            "List of Request for a Visa Document / Dependent (of Personnel)",
          menuItem: [
            {
              key: "List-Follower-Doc1",
              text_th: "เอกสารยื่นคำร้องขอรับการตรวจลงตราประเภทคนอยู่ชั่วคราว",
              text_en:
                "Non-Immigrant Visa Application at Thai Embassy or Consulate",
              path: "/List-Follower-Doc1",
              name: "List-Follower-Doc1",
            },
            {
              key: "List-Follower-Doc2",
              text_th: "เอกสารขออยู่ต่อ/ขอเปลี่ยนสังกัด",
              text_en: "Visa Extension/Change of Affiliation",
              path: "/List-Follower-Doc2",
              name: "List-Follower-Doc2",
            },
            {
              key: "List-Follower-Doc3",
              text_th: "เอกสารขอรับการตรวจลงตรา",
              text_en:
                "Issuing Visa (From Visa on Arrival/Free Visa to Non-Immigrant Visa)",
              path: "/List-Follower-Doc3",
              name: "List-Follower-Doc3",
            },
            {
              key: "List-Follower-Doc4",
              text_th: "เอกสารขอเปลี่ยนประเภทการตรวจลงตรา",
              text_en:
                "Visa Status Alteration (From Tourist Visa or Transit Visa to Non-Immigrant Visa)",
              path: "/List-Follower-Doc4",
              name: "List-Follower-Doc4",
            },
            {
              key: "List-Follower-Doc5",
              text_th: "เอกสารขอตรวจสอบประวัติ",
              text_en: "Criminal Record Check",
              path: "/List-Follower-Doc5",
              name: "List-Follower-Doc5",
            },
            {
              key: "List-Follower-Doc6",
              text_th: "เอกสารขอยกเลิกการตรวจลงตรา",
              text_en: "Visa Termination",
              path: "/List-Follower-Doc6",
              name: "List-Follower-Doc6",
            },
          ],
        },
      ],

      Navigator: [],
      EngLang: "",
      fab: false,
      transition: "slide-y-reverse-transition",
      userData: [],
    };
  },
  created() {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.EngLang = true;
      this.itemChangeLang = "name_en";
    } else if (lang == "th") {
      this.EngLang = false;
      this.itemChangeLang = "name_th";
    }
    // this.getHeader();
    if (Object.prototype.hasOwnProperty.call(localStorage, "userCUdata")) {
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      console.log("this.userData", this.userData);
      if (this.userData.user_type == "SUPERADMIN") {
        this.Navigator = this.NavigatorDefault;
      } else if (this.userData.user_type == "ADMIN") {
        this.Navigator = this.NavigatorAdmin;
        // chem
        // 123456
      } else if (this.userData.user_type == "STUDENT") {
        this.Navigator = this.NavigatorStudent;
        // student
        // 123456
      } else if (this.userData.user_type == "TEACHER") {
        this.Navigator = this.NavigatorTeacher;
        // teacher
        // 123456
      }
    } else {
      this.$router.push("/");
    }
    // this.userData = JSON.parse(
    //   Decode.decode(localStorage.getItem("userCUdata"))
    // );

    // this.Navigator = this.NavigatorDefault;

    // this.CheckHeader();
  },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
  methods: {
    handlechangelanguage(event) {
      if (event == true) {
        this.EngLang = "en";
      } else if (event == false) {
        this.EngLang = "th";
      }
      localStorage.setItem("lang", this.EngLang);
      console.log("event", this.EngLang);
      window.location.reload();
    },
    goToProfile() {
      this.$router.push(`/profile`);
    },
    goTo(val) {
      this.$router.push({ path: val.path }).catch(() => {});
    },
    openKeys(val) {
      console.log("openKeys", val);
    },
    handleClick(e) {
      console.log("click", e);
    },
    titleClick(e) {
      console.log("titleClick", e);
    },
    async ChangePage(val) {
      this.selectedItem = val.key;
      // console.log("เข้า ChangePage", val);
      // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      console.log("CheckHeader", this.$router.currentRoute.name);
      // this.Header = val;
      if (this.$router.currentRoute.name == "Index") {
        this.invisible = "Index";
      } else {
        this.invisible = "";
      }
    },
    Logout() {
      localStorage.removeItem("userCUdata");
      window.location.reload();
    },
    goToNotification() {
      this.$router.push({ path: "/Notification" }).catch(() => {});
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  /* background-color: rgb(235, 240, 248); */
  overflow: auto;
  width: 100%;
  height: 94vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
.highlighted {
  background-color: #9f9f9f;
}
</style>
