<template>
  <v-app style="background-color: #e0e0e0">
    <router-view />
  </v-app>
</template>

<script>
export default {
  methods: {},
};
</script>
<style>
@import "./assets/styles.css";
html {
  font-family: "Chula" !important;
}
.sticky {
  position: fixed;
  top: 6%;
  width: 100%;
  z-index: 1;
}
</style>

