import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "@/views/Homepage.vue";
import Login from "@/views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/View-Doc8",
    name: "View-Doc8",
    component: () => import("@/components/Doc1/View-Doc8.vue"),
  },
  {
    path: "/View-doc10",
    name: "View-doc10",
    component: () => import("@/components/Doc1/View-doc10.vue"),
  },

  {
    path: "/register",
    name: "register",
    component: () => import("@/views/registerForm.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Profile.vue"),
  },
  {
    path: "/Home",
    component: Homepage,
    children: [
      // {
      //     path: '/test',
      //     name: 'test',
      //     component: () => import ("@/views/test.vue")
      // },
      {
        path: "/Doc1",
        name: "Doc1",
        component: () => import("@/components/Doc1/Doc1.vue"),
      },
      {
        path: "/Doc2",
        name: "Doc2",
        component: () => import("@/components/Doc1/Doc2.vue"),
      },
      {
        path: "/Doc3",
        name: "Doc3",
        component: () => import("@/components/Doc1/Doc3.vue"),
      },
      {
        path: "/Doc4",
        name: "Doc4",
        component: () => import("@/components/Doc1/Doc4.vue"),
      },
      {
        path: "/Doc5",
        name: "Doc5",
        component: () => import("@/components/Doc1/Doc5.vue"),
      },
      {
        path: "/Doc6",
        name: "Doc6",
        component: () => import("@/components/Doc1/Doc6.vue"),
      },
      {
        path: "/Doc7",
        name: "Doc7",
        component: () => import("@/components/Doc1/Doc7.vue"),
      },
      {
        path: "/Doc8",
        name: "Doc8",
        component: () => import("@/components/Doc1/Doc8.vue"),
      },

      {
        path: "/List-Doc1",
        name: "List-Doc1",
        component: () => import("@/components/Doc1/List-Doc1.vue"),
      },
      {
        path: "/List-Doc2",
        name: "List-Doc2",
        component: () => import("@/components/Doc1/List-Doc2.vue"),
      },
      {
        path: "/List-Doc3",
        name: "List-Doc3",
        component: () => import("@/components/Doc1/List-Doc3.vue"),
      },
      {
        path: "/List-Doc4",
        name: "List-Doc4",
        component: () => import("@/components/Doc1/List-Doc4.vue"),
      },
      {
        path: "/List-Doc5",
        name: "List-Doc5",
        component: () => import("@/components/Doc1/List-Doc5.vue"),
      },
      {
        path: "/List-Doc6",
        name: "List-Doc6",
        component: () => import("@/components/Doc1/List-Doc6.vue"),
      },
      {
        path: "/Edit-Doc1",
        name: "Edit-Doc1",
        component: () => import("@/components/Doc1/Edit-Doc1.vue"),
      },
      {
        path: "/Edit-Doc2",
        name: "Edit-Doc2",
        component: () => import("@/components/Doc1/Edit-Doc2.vue"),
      },
      {
        path: "/Edit-Doc3",
        name: "Edit-Doc3",
        component: () => import("@/components/Doc1/Edit-Doc3.vue"),
      },
      {
        path: "/Edit-Doc4",
        name: "Edit-Doc4",
        component: () => import("@/components/Doc1/Edit-Doc4.vue"),
      },
      {
        path: "/Edit-Doc5",
        name: "Edit-Doc5",
        component: () => import("@/components/Doc1/Edit-Doc5.vue"),
      },
      {
        path: "/Edit-Doc6",
        name: "Edit-Doc6",
        component: () => import("@/components/Doc1/Edit-Doc6.vue"),
      },
      {
        path: "/Edit-Doc7",
        name: "Edit-Doc7",
        component: () => import("@/components/Doc1/Edit-Doc7.vue"),
      },
      {
        path: "/Edit-Doc8",
        name: "Edit-Doc8",
        component: () => import("@/components/Doc1/Edit-Doc8.vue"),
      },

      {
        path: "/View-Doc1",
        name: "View-Doc1",
        component: () => import("@/components/Doc1/View-Doc1.vue"),
      },
      {
        path: "/View-Doc2",
        name: "View-Doc2",
        component: () => import("@/components/Doc1/View-Doc2.vue"),
      },
      {
        path: "/View-Doc3",
        name: "View-Doc3",
        component: () => import("@/components/Doc1/View-Doc3.vue"),
      },
      {
        path: "/View-Doc4",
        name: "View-Doc4",
        component: () => import("@/components/Doc1/View-Doc4.vue"),
      },
      {
        path: "/View-Doc5",
        name: "View-Doc5",
        component: () => import("@/components/Doc1/View-Doc5.vue"),
      },
      {
        path: "/View-Doc6",
        name: "View-Doc6",
        component: () => import("@/components/Doc1/View-Doc6.vue"),
      },
      {
        path: "/View-Doc7",
        name: "View-Doc7",
        component: () => import("@/components/Doc1/View-Doc7.vue"),
      },
      // {
      //   path: "/View-Doc8",
      //   name: "View-Doc8",
      //   component: () => import("@/components/Doc1/View-Doc8.vue"),
      // },
      {
        path: "/View-Doc8",
        name: "View-Doc9",
        component: () => import("@/components/Doc1/View-Doc9.vue"),
      },

      {
        path: "/View-qrcode",
        name: "View-qrcode",
        component: () => import("@/components/Doc1/View-qrcode.vue"),
      },
      {
        path: "/View-doc10",
        name: "View-doc10",
        component: () => import("@/components/Doc1/View-doc10.vue"),
      },
      {
        path: "/View-Profile",
        name: "View-Profile",
        component: () => import("@/components/Doc1/View-Profile.vue"),
      },

      {
        path: "/FollowerStudent-Doc1",
        name: "FollowerStudent-Doc1",
        component: () =>
          import("@/components/FollowerStudent-Doc1/FollowerStudent-Doc1.vue"),
      },
      {
        path: "/FollowerStudent-Doc2",
        name: "FollowerStudent-Doc2",
        component: () =>
          import("@/components/FollowerStudent-Doc1/FollowerStudent-Doc2.vue"),
      },
      {
        path: "/FollowerStudent-Doc3",
        name: "FollowerStudent-Doc3",
        component: () =>
          import("@/components/FollowerStudent-Doc1/FollowerStudent-Doc3.vue"),
      },
      {
        path: "/FollowerStudent-Doc4",
        name: "FollowerStudent-Doc4",
        component: () =>
          import("@/components/FollowerStudent-Doc1/FollowerStudent-Doc4.vue"),
      },
      {
        path: "/FollowerStudent-Doc5",
        name: "FollowerStudent-Doc5",
        component: () =>
          import("@/components/FollowerStudent-Doc1/FollowerStudent-Doc5.vue"),
      },
      {
        path: "/FollowerStudent-Doc6",
        name: "FollowerStudent-Doc6",
        component: () =>
          import("@/components/FollowerStudent-Doc1/FollowerStudent-Doc6.vue"),
      },
      {
        path: "/Edit-FollowerStudent-Doc1",
        name: "Edit-FollowerStudent-Doc1",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/Edit-FollowerStudent-Doc1.vue"
          ),
      },
      {
        path: "/Edit-FollowerStudent-Doc2",
        name: "Edit-FollowerStudent-Doc2",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/Edit-FollowerStudent-Doc2.vue"
          ),
      },
      {
        path: "/Edit-FollowerStudent-Doc3",
        name: "Edit-FollowerStudent-Doc3",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/Edit-FollowerStudent-Doc3.vue"
          ),
      },
      {
        path: "/Edit-FollowerStudent-Doc4",
        name: "Edit-FollowerStudent-Doc4",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/Edit-FollowerStudent-Doc4.vue"
          ),
      },
      {
        path: "/Edit-FollowerStudent-Doc5",
        name: "Edit-FollowerStudent-Doc5",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/Edit-FollowerStudent-Doc5.vue"
          ),
      },
      {
        path: "/Edit-FollowerStudent-Doc6",
        name: "Edit-FollowerStudent-Doc6",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/Edit-FollowerStudent-Doc6.vue"
          ),
      },
      {
        path: "/List-FollowerStudent-Doc1",
        name: "List-FollowerStudent-Doc1",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/List-FollowerStudent-Doc1.vue"
          ),
      },
      {
        path: "/List-FollowerStudent-Doc2",
        name: "List-FollowerStudent-Doc2",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/List-FollowerStudent-Doc2.vue"
          ),
      },
      {
        path: "/List-FollowerStudent-Doc3",
        name: "List-FollowerStudent-Doc3",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/List-FollowerStudent-Doc3.vue"
          ),
      },
      {
        path: "/List-FollowerStudent-Doc4",
        name: "List-FollowerStudent-Doc4",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/List-FollowerStudent-Doc4.vue"
          ),
      },
      {
        path: "/List-FollowerStudent-Doc5",
        name: "List-FollowerStudent-Doc5",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/List-FollowerStudent-Doc5.vue"
          ),
      },
      {
        path: "/List-FollowerStudent-Doc6",
        name: "List-FollowerStudent-Doc6",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/List-FollowerStudent-Doc6.vue"
          ),
      },
      {
        path: "/View-FollowerStudent-Doc1",
        name: "View-FollowerStudent-Doc1",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/View-FollowerStudent-Doc1.vue"
          ),
      },
      {
        path: "/View-FollowerStudent-Doc2",
        name: "View-FollowerStudent-Doc2",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/View-FollowerStudent-Doc2.vue"
          ),
      },
      {
        path: "/View-FollowerStudent-Doc3",
        name: "View-FollowerStudent-Doc3",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/View-FollowerStudent-Doc3.vue"
          ),
      },
      {
        path: "/View-FollowerStudent-Doc4",
        name: "View-FollowerStudent-Doc4",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/View-FollowerStudent-Doc4.vue"
          ),
      },
      {
        path: "/View-FollowerStudent-Doc5",
        name: "View-FollowerStudent-Doc5",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/View-FollowerStudent-Doc5.vue"
          ),
      },
      {
        path: "/View-FollowerStudent-Doc6",
        name: "View-FollowerStudent-Doc6",
        component: () =>
          import(
            "@/components/FollowerStudent-Doc1/View-FollowerStudent-Doc6.vue"
          ),
      },
      {
        path: "/Personnel-Doc1",
        name: "Personal-Doc1",
        component: () => import("@/components/Personnel/Personnel-Doc1.vue"),
      },
      {
        path: "/Personnel-Doc2",
        name: "Personal-Doc2",
        component: () => import("@/components/Personnel/Personnel-Doc2.vue"),
      },
      {
        path: "/Personnel-Doc3",
        name: "Personal-Doc3",
        component: () => import("@/components/Personnel/Personnel-Doc3.vue"),
      },
      {
        path: "/Personnel-Doc4",
        name: "Personal-Doc4",
        component: () => import("@/components/Personnel/Personnel-Doc4.vue"),
      },
      {
        path: "/Personnel-Doc5",
        name: "Personal-Doc5",
        component: () => import("@/components/Personnel/Personnel-Doc5.vue"),
      },
      {
        path: "/Personnel-Doc6",
        name: "Personnel-Doc6",
        component: () => import("@/components/Personnel/Personnel-Doc6.vue"),
      },
      {
        path: "/List-Personnel-Doc1",
        name: "List-Personnel-Doc1",
        component: () =>
          import("@/components/Personnel/List-Personnel-Doc1.vue"),
      },
      {
        path: "/List-Personnel-Doc2",
        name: "List-Personnel-Doc2",
        component: () =>
          import("@/components/Personnel/List-Personnel-Doc2.vue"),
      },
      {
        path: "/List-Personnel-Doc3",
        name: "List-Personnel-Doc3",
        component: () =>
          import("@/components/Personnel/List-Personnel-Doc3.vue"),
      },
      {
        path: "/List-Personnel-Doc4",
        name: "List-Personnel-Doc4",
        component: () =>
          import("@/components/Personnel/List-Personnel-Doc4.vue"),
      },
      {
        path: "/List-Personnel-Doc5",
        name: "List-Personnel-Doc5",
        component: () =>
          import("@/components/Personnel/List-Personnel-Doc5.vue"),
      },
      {
        path: "/List-Personnel-Doc6",
        name: "List-Personnel-Doc6",
        component: () =>
          import("@/components/Personnel/List-Personnel-Doc6.vue"),
      },
      {
        path: "/Edit-Personnel-Doc1",
        name: "Edit-Personnel-Doc1",
        component: () =>
          import("@/components/Personnel/Edit-Personnel-Doc1.vue"),
      },
      {
        path: "/Edit-Personnel-Doc2",
        name: "Edit-Personnel-Doc2",
        component: () =>
          import("@/components/Personnel/Edit-Personnel-Doc2.vue"),
      },
      {
        path: "/Edit-Personnel-Doc3",
        name: "Edit-Personnel-Doc3",
        component: () =>
          import("@/components/Personnel/Edit-Personnel-Doc3.vue"),
      },
      {
        path: "/Edit-Personnel-Doc4",
        name: "Edit-Personnel-Doc4",
        component: () =>
          import("@/components/Personnel/Edit-Personnel-Doc4.vue"),
      },
      {
        path: "/Edit-Personnel-Doc5",
        name: "Edit-Personnel-Doc5",
        component: () =>
          import("@/components/Personnel/Edit-Personnel-Doc5.vue"),
      },
      {
        path: "/Edit-Personnel-Doc6",
        name: "Edit-Personnel-Doc6",
        component: () =>
          import("@/components/Personnel/Edit-Personnel-Doc6.vue"),
      },
      {
        path: "/View-Personnel-Doc1",
        name: "View-Personnel-Doc1",
        component: () =>
          import("@/components/Personnel/View-Personnel-Doc1.vue"),
      },
      {
        path: "/View-Personnel-Doc2",
        name: "View-Personnel-Doc2",
        component: () =>
          import("@/components/Personnel/View-Personnel-Doc2.vue"),
      },
      {
        path: "/View-Personnel-Doc3",
        name: "View-Personnel-Doc3",
        component: () =>
          import("@/components/Personnel/View-Personnel-Doc3.vue"),
      },
      {
        path: "/View-Personnel-Doc4",
        name: "View-Personnel-Doc4",
        component: () =>
          import("@/components/Personnel/View-Personnel-Doc4.vue"),
      },
      {
        path: "/View-Personnel-Doc5",
        name: "View-Personnel-Doc5",
        component: () =>
          import("@/components/Personnel/View-Personnel-Doc5.vue"),
      },
      {
        path: "/View-Personnel-Doc6",
        name: "View-Personnel-Doc6",
        component: () =>
          import("@/components/Personnel/View-Personnel-Doc6.vue"),
      },
      {
        path: "/Follower-Doc1",
        name: "Follower-Doc1",
        component: () => import("@/components/Follower/Follower-Doc1.vue"),
      },
      {
        path: "/Follower-Doc2",
        name: "Follower-Doc2",
        component: () => import("@/components/Follower/Follower-Doc2.vue"),
      },
      {
        path: "/Follower-Doc3",
        name: "Follower-Doc3",
        component: () => import("@/components/Follower/Follower-Doc3.vue"),
      },
      {
        path: "/Follower-Doc4",
        name: "Follower-Doc4",
        component: () => import("@/components/Follower/Follower-Doc4.vue"),
      },
      {
        path: "/Follower-Doc5",
        name: "Follower-Doc5",
        component: () => import("@/components/Follower/Follower-Doc5.vue"),
      },
      {
        path: "/Follower-Doc6",
        name: "Follower-Doc6",
        component: () => import("@/components/Follower/Follower-Doc6.vue"),
      },
      {
        path: "/List-Follower-Doc1",
        name: "List-Follower-Doc1",
        component: () => import("@/components/Follower/List-Follower-Doc1.vue"),
      },
      {
        path: "/List-Follower-Doc2",
        name: "List-Follower-Doc2",
        component: () => import("@/components/Follower/List-Follower-Doc2.vue"),
      },
      {
        path: "/List-Follower-Doc3",
        name: "List-Follower-Doc3",
        component: () => import("@/components/Follower/List-Follower-Doc3.vue"),
      },
      {
        path: "/List-Follower-Doc4",
        name: "List-Follower-Doc4",
        component: () => import("@/components/Follower/List-Follower-Doc4.vue"),
      },
      {
        path: "/List-Follower-Doc5",
        name: "List-Follower-Doc5",
        component: () => import("@/components/Follower/List-Follower-Doc5.vue"),
      },
      {
        path: "/List-Follower-Doc6",
        name: "List-Follower-Doc6",
        component: () => import("@/components/Follower/List-Follower-Doc6.vue"),
      },
      {
        path: "/Edit-Follower-Doc1",
        name: "Edit-Follower-Doc1",
        component: () => import("@/components/Follower/Edit-Follower-Doc1.vue"),
      },
      {
        path: "/Edit-Follower-Doc2",
        name: "Edit-Follower-Doc2",
        component: () => import("@/components/Follower/Edit-Follower-Doc2.vue"),
      },
      {
        path: "/Edit-Follower-Doc3",
        name: "Edit-Follower-Doc3",
        component: () => import("@/components/Follower/Edit-Follower-Doc3.vue"),
      },
      {
        path: "/Edit-Follower-Doc4",
        name: "Edit-Follower-Doc4",
        component: () => import("@/components/Follower/Edit-Follower-Doc4.vue"),
      },
      {
        path: "/Edit-Follower-Doc5",
        name: "Edit-Follower-Doc5",
        component: () => import("@/components/Follower/Edit-Follower-Doc5.vue"),
      },
      {
        path: "/Edit-Follower-Doc6",
        name: "Edit-Follower-Doc6",
        component: () => import("@/components/Follower/Edit-Follower-Doc6.vue"),
      },
      {
        path: "/View-Follower-Doc1",
        name: "View-Follower-Doc1",
        component: () => import("@/components/Follower/View-Follower-Doc1.vue"),
      },
      {
        path: "/View-Follower-Doc2",
        name: "View-Follower-Doc2",
        component: () => import("@/components/Follower/View-Follower-Doc2.vue"),
      },
      {
        path: "/View-Follower-Doc3",
        name: "View-Follower-Doc3",
        component: () => import("@/components/Follower/View-Follower-Doc3.vue"),
      },
      {
        path: "/View-Follower-Doc4",
        name: "View-Follower-Doc4",
        component: () => import("@/components/Follower/View-Follower-Doc4.vue"),
      },
      {
        path: "/View-Follower-Doc5",
        name: "View-Follower-Doc5",
        component: () => import("@/components/Follower/View-Follower-Doc5.vue"),
      },
      {
        path: "/View-Follower-Doc6",
        name: "View-Follower-Doc6",
        component: () => import("@/components/Follower/View-Follower-Doc6.vue"),
      },
      // {
      //     path: '/ManageNationality',
      //     name: 'ManageNationality',
      //     component: () => import ("@/components/Master/ManageNationality.vue")
      // },
      {
        path: "/ManageAffilationFaculty",
        name: "ManageAffilationFaculty",
        component: () =>
          import("@/components/Master/ManageAffilationFaculty.vue"),
      },
      {
        path: "/ManageBoosterApplication",
        name: "ManageBoosterApplication",
        component: () =>
          import("@/components/Master/ManageBoosterApplication.vue"),
      },
      {
        path: "/ManageCovidVaccine",
        name: "ManageCovidVaccine",
        component: () => import("@/components/Master/ManageCovidVaccine.vue"),
      },
      {
        path: "/ManageDepartment",
        name: "ManageDepartment",
        component: () => import("@/components/Master/ManageDepartment.vue"),
      },
      {
        path: "/ManageCountry",
        name: "ManageCountry",
        component: () => import("@/components/Master/ManageCountry.vue"),
      },

      {
        path: "/ManageQR",
        name: "ManageQR",
        component: () => import("@/components/Master/ManageQR.vue"),
      },

      {
        path: "/ManageDocument",
        name: "ManageDocument",
        component: () => import("@/components/Master/ManageDocument.vue"),
      },

      {
        path: "/ManageCity",
        name: "ManageCity",
        component: () => import("@/components/Master/ManageCity.vue"),
      },
      {
        path: "/ManageEmbassy",
        name: "ManageEmbassy",
        component: () => import("@/components/Master/ManageEmbassy.vue"),
      },
      {
        path: "/ManageExchangeVisittingStudent",
        name: "ManageExchangeVisittingStudent",
        component: () =>
          import("@/components/Master/ManageExchangeVisittingStudent.vue"),
      },
      {
        path: "/ManageNationality",
        name: "ManageNationality",
        component: () => import("@/components/Master/ManageNationality.vue"),
      },
      {
        path: "/ManageRelationWithHolder",
        name: "ManageRelationWithHolder",
        component: () =>
          import("@/components/Master/ManageRelationWithHolder.vue"),
      },
      {
        path: "/ManageReasonForVisaCancellation",
        name: "ManageReasonForVisaCancellation",
        component: () =>
          import("@/components/Master/ManageReasonForVisaCancellation.vue"),
      },
      {
        path: "/ManageLevel",
        name: "ManageLevel",
        component: () => import("@/components/Master/ManageLevel.vue"),
      },
      {
        path: "/ManageStudentType",
        name: "ManageStudentType",
        component: () => import("@/components/Master/ManageStudentType.vue"),
      },
      {
        path: "/ManageSub_StudentType",
        name: "ManageSub_StudentType",
        component: () =>
          import("@/components/Master/ManageSub_StudentType.vue"),
      },
      {
        path: "/ManageSupportingDocument",
        name: "ManageSupportingDocument",
        component: () =>
          import("@/components/Master/ManageSupportingDocument.vue"),
      },
      {
        path: "/ManageTypeOfWorks",
        name: "ManageTypeOfWorks",
        component: () => import("@/components/Master/ManageTypeOfWorks.vue"),
      },
      {
        path: "/ManageVisaType",
        name: "ManageVisaType",
        component: () => import("@/components/Master/ManageVisaType.vue"),
      },
      {
        path: "/ManageUser",
        name: "ManageUser",
        component: () => import("@/components/Master/ManageUser.vue"),
      },
      {
        path: "/ManageUserAdmin",
        name: "ManageUserAdmin",
        component: () => import("@/components/Master/ManageUserAdmin.vue"),
      },
      {
        path: "/ManageChangePassword",
        name: "ManageChangePassword",
        component: () => import("@/components/Master/ManageChangePassword.vue"),
      },
    ],
  },

  // {
  // path: '/',
  // name: 'Home',
  // component: Home
  // },
  // {
  // path: '/about',
  // name: 'About',
  // // route level code-splitting
  // // this generates a separate chunk (about.[hash].js) for this route
  // // which is lazy-loaded when the route is visited.
  // component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
