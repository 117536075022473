<template>
  <v-app id="inspire">
    <v-main>
      <!-- <v-img src="@/assets/CU1Background.jpeg" style="object-fit: fill;"> -->
      <v-container fluid fill-height class="backgroundBi">
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md5 style="text-align: -webkit-center">
            <v-row justify="center" align="center">
              <!-- src="@/assets/logo/ทางเลือก-สองสี-หลังใส.png" -->
              <!-- src="https://careers.chula.ac.th/assets/img/logo.png" -->

              <v-img
                src="https://careers.chula.ac.th/assets/img/logo.png"
                max-width="400px"
                max-height="200px"
              />

              <!-- <span
                style="font-weight: 800; font-size: 24px; color: white"
                class="mt-2"
              >
                VISA Service
              </span> -->
              <br />
              <v-card class="elevation-12 mt-0 pa-10 my-6" width="90vw">
                <v-row justify="center" class="px-10">
                  <!-- <v-toolbar color="#DB5F8E" style="color: white">
                  <p class="font-weight-light mt-4" style="font-size: 20px">
                    Login
                  </p>
                </v-toolbar> -->

                  <v-card-title
                    class="font-weight-light pb-6"
                    style="font-size: 32px"
                  >
                    VISA System
                  </v-card-title>

                  <v-col cols="12">
                    <!-- <v-row align="center" justify="center"> -->
                    <v-form
                      ref="loginfrom"
                      v-model="loginfrom"
                      :lazy-validation="lazy"
                    >
                      <v-col cols="12" md="12">
                        <v-text-field
                          placeholder="login"
                          type="text"
                          solo
                          dense
                          v-model="username"
                          :rules="Rules.empty"
                          hide-details="auto"
                          @keyup.enter="login()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          placeholder="password"
                          type="password"
                          solo
                          dense
                          v-model="password"
                          :rules="Rules.empty"
                          hide-details="auto"
                          @keyup.enter="login()"
                        ></v-text-field>
                      </v-col>
                    </v-form>
                    <!-- </v-row> -->
                  </v-col>
                  <v-col cols="12" class="mb-10">
                    <v-row class="mx-0 d-flex justify-space-between">
                      <v-btn text dense @click="gotoRegister()">Register</v-btn>
                      <v-btn text dense>Forgot Password ?</v-btn>
                    </v-row>
                  </v-col>
                  <!-- <v-card-actions> -->
                  <!-- <v-spacer></v-spacer> -->
                  <v-col cols="12">
                    <v-btn
                      color="#DB5F8E"
                      style="color: white"
                      class="font-weight-light"
                      @click="login()"
                      block
                      >Login</v-btn
                    >
                  </v-col>
                  <!-- </v-card-actions> -->
                </v-row>
              </v-card>
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
      <!-- </v-img> -->
    </v-main>
  </v-app>
</template>
<script>
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      userData: [],
      lazy: "",
      loginfrom: "",
      Rules: {
        empty: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      username: "",
      password: "",
    };
  },
  created() {
    if (Object.prototype.hasOwnProperty.call(localStorage, "userCUdata")) {
      var checklogin = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      ).name;
      if (checklogin !== "") {
        // console.log(checklogin);
        // this.$router.push("/report");
        // this.$router.push("/Dashboard");
        this.$router.push("/Doc1");
      }
    }
  },
  methods: {
    gotoRegister() {
      this.$router.push("/register");
    },
    resetValidation() {
      this.$refs.loginfrom.resetValidation();
    },
    async login() {
      const login = {
        username: this.username,
        password: this.password,
      };
      if (!this.username || !this.password) {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอก Username และ Password ให้ถูกต้อง",
        });
      } else {
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/user/login`,
          login
        );
        console.log("login", response.data.data);
        if (response.data.response_status === "SUCCESS") {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            icon: "success",
            text: "เข้าสู่ระบบเสร็จสิ้น",
          });

          localStorage.setItem("userCUdata", Encode.encode(response.data.data));
          if (response.data.data.user_type == "SUPERADMIN") {
            await this.$router.push("/List-Doc1");
          } else if (response.data.data.user_type == "ADMIN") {
            await this.$router.push("/List-Doc1");
          } else if (response.data.data.user_type == "STUDENT") {
            await this.$router.push("/List-Doc1");
          } else if (response.data.data.user_type == "TEACHER") {
            await this.$router.push("/List-Personnel-Doc1");
          }
          // await this.$router.push("/Doc1");
        } else if (response.data.response_status === "ERROR") {
          console.log("response", response);
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            icon: "error",
            text: response.data.message,
          });
        }
      }

      // if (this.username == "admin" && this.password == "1234") {
      //   localStorage.setItem("userCUdata", Encode.encode(login));
      //   await this.$swal.fire({
      //     showConfirmButton: false,
      //     timer: 1000,
      //     timerProgressBar: true,
      //     icon: "success",
      //     text: "เข้าสู่ระบบเสร็จสิ้น",
      //   });
      //   await this.$router.push("/Doc1");
      // } else {
      //   await this.$swal.fire({
      //     showConfirmButton: false,
      //     timer: 1000,
      //     timerProgressBar: true,
      //     icon: "error",
      //     text: response.data.message,
      //   });
      // }
    },
  },
};
</script>
<style scoped>
.backgroundBi {
  background-color: #ffa0c4;
  background-image: url(`@/assets/backgroundBi.png`);
  position: absolute;
}
</style>
